import React from 'react';
import styled from 'styled-components';
import { Carousel } from 'react-responsive-carousel';
import '../styles/carousel.css';

const CarouselContainer = styled.div`
  margin: ${props => props.margin || '0 0 75px 0'};
  width: 100%;

  @media (max-width: 767px) {
    margin: ${props => props.mobileMargin || '0 0 0 0'};
  }
`;

const CarouselInnerContainer = styled.div`
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 200px);
  padding: 40px; //54px 0 42px 0;

  display: flex;
  justify-content: space-between;
  align-items: center;

  flex-direction: column;

  @media (max-width: 767px) {
    padding: 20px 0;
    max-height: 60vh;
  }
`;

const CarouselImageContainer = styled.div`
  height: 100%;
  width: 90%; //calc(100% - 150px);
  display: flex;
  align-items: center;

  @media (max-width: 767px) {
    width: calc(100% - 40px);
  }
`;

const PortraitImage = styled.img`
  object-fit: contain;
  max-height: 100%;
  width: auto !important;
  margin: 0 auto;
`;
const LandscapeImage = styled.img`
  object-fit: contain;
  height: 100%;
  max-height: 100%;
  margin: 0 auto;
`;

const Caption = styled.p`
  color: #fff;
  margin-top: 42px;

  @media (max-width: 767px) {
    margin-top: 0;
    padding: 20px 20px 0 20px;
  }
`;

const CaptionCount = styled.span`
  font-variant-numeric: diagonal-fractions;
`;

class Gallery extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentSlide: 0,
      totalSlides: 0,
    };

    this.updateCurrentSlide = this.updateCurrentSlide.bind(this);
  }

  componentDidMount(props) {
    this.setState({
      totalSlides: this.props.images.length,
    });
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.images.length !== prevProps.images.length) {
      this.setState({
        totalSlides: this.props.images.length,
      });
    }
  }

  updateCurrentSlide = index => {
    const { currentSlide } = this.state.currentSlide;
    if (currentSlide !== index) {
      this.setState({
        currentSlide: index,
      });
    }
  };

  render(props) {
    const galleryImages = this.props.images
      .filter(imageData => imageData.gallery_image !== null)
      .filter(imageData => imageData.gallery_image !== undefined)
      .filter(imageData => imageData.gallery_image.url !== null)
      .map((imageData, index) => {
        let imageHeight = imageData.gallery_image.dimensions.height;
        let imageWidth = imageData.gallery_image.dimensions.width;

        if (imageHeight > imageWidth) {
          return (
            <CarouselInnerContainer key={index}>
              <CarouselImageContainer>
                <PortraitImage
                  src={imageData.gallery_image.url}
                  alt={imageData.gallery_image.copyright}
                />
              </CarouselImageContainer>
              {/* <Caption>
              <CaptionCount>
                {this.state.currentSlide +
                  1 +
                  '/' +
                  this.state.totalSlides +
                  ' '}
              </CaptionCount>
              {imageData.gallery_image.copyright}
            </Caption> */}
            </CarouselInnerContainer>
          );
        } else {
          return (
            <CarouselInnerContainer key={index}>
              <CarouselImageContainer>
                <LandscapeImage
                  src={imageData.gallery_image.url}
                  alt={imageData.gallery_image.copyright}
                />
              </CarouselImageContainer>
              {/* <Caption>
              <CaptionCount>
                {this.state.currentSlide +
                  1 +
                  '/' +
                  this.state.totalSlides +
                  ' '}
              </CaptionCount>

              {imageData.gallery_image.copyright}
            </Caption> */}
            </CarouselInnerContainer>
          );
        }
      });

    return (
      <CarouselContainer
        margin={this.props.margin}
        mobileMargin={this.props.mobileMargin}
      >
        <Carousel
          showThumbs={false}
          showIndicators={false}
          infiniteLoop={true}
          centerMode
          centerSlidePercentage={80}
          swipeable={true}
          useKeyboardArrows
          emulateTouch
          showStatus={false}
          selectedItem={this.state.currentSlide}
          onChange={this.updateCurrentSlide}
        >
          {galleryImages}
        </Carousel>
      </CarouselContainer>
    );
  }
}

export default Gallery;
